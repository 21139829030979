export default function Chip() {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: 10,
                    fontWeight: 600,
                    height: "auto",
                    borderRadius: ".25rem",
                },
                label: {
                    padding: ".025rem .3rem",
                },
                colorError: {
                    backgroundColor: "#f46a6a",
                },
                colorSuccess: {
                    backgroundColor: "#34c38f",
                },
            },
        },
    };
}
