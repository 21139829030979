import createStore from "react-superstore";

// import { Channel } from 'hooks/channel';

export type Signal = {
    id: number;
    symbol: string;
    side: string;
    date: Date;
    entries: string[];
    profits: string[];
    stop_loss: string;
};

export type Channel = {
    slug: string;
    name: string;
    signals: Signal[];
};

export type DisplayFormat = {
    returns: boolean;
};

export type Configuration = {
    displayFormat: DisplayFormat;
};

export type Store = {
    configuration: Configuration;
    channels: Channel[];
};

const store: Store = {
    configuration: {
        displayFormat: {
            returns: false,
        },
    },
    channels: [],
};

export const [useStore, setStore, getStore] = createStore(store);

export const setChannels = (channels: Channel[]) => {
    for (let index = 0; index < channels.length; index++) {
        if (!channels[index].signals) {
            channels[index].signals = [];
        }
    }

    setStore({ channels });
};

export const getChannel = (slug: string): Channel => {
    const channels = useStore((store: Store) => store.channels);
    const channel = channels.find((c: Channel) => c.slug === slug);
    if (!channel) {
        console.log("Failed to find channel", slug);
    }
    return channel;
};

export const setSignals = (channel: Channel, signals: Signal[]) => {
    const store = getStore();
    setStore({
        ...store,
        channels: store.channels.map((c: Channel) => {
            if (c.slug === channel.slug) {
                // Convert signal date to date object
                c.signals = signals.map((s: Signal, index: number) => {
                    s.id = index + 1;
                    s.date = new Date(s.date);
                    return s;
                });
            }
            return c;
        }),
    });
};
