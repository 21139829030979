// project import
import dashboard from "./dashboard";
import telegram from "./telegram";

export interface MenuItemChip {
    color:
        | "default"
        | "error"
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning";
    variant: "filled" | "outlined";
    size: "small" | "medium";
    label?: React.ReactNode;
    avatar?: React.ReactElement;
}

export interface MenuItem {
    id: string;
    disabled?: boolean;
    title: string;
    type: string;
    url: string;
    /* eslint-disable */
    icon: any;
    breadcrumbs: boolean;
    chip?: MenuItemChip;
}

export interface GroupItem {
    id: string;
    title: string;
    type: string;
    children: MenuItem[];
}

export interface GroupItems {
    items: GroupItem[];
}

const menuItems = {
    items: [dashboard, telegram],
};

export default menuItems;
