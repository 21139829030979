import React from "react";

import { Stack, Box, CircularProgress, Grid, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme, useMediaQuery } from "@mui/material";
import { apiURL } from "config";
import { fetchWithAuth, handleHTTPError, handleHTTPResponse } from "utilities";
import Information from "./Information";
import Chart from "./Chart";
import OrderTable from "./OrderTable";
import IncomeTable from "./IncomeTable";
import Metrics from "./Metrics";
import ExecutionChart from "./ExecutionChart";
import Logs from "./Logs";
import { Order, Price, Income, SignalDetail } from "./model";

export default function SignalDetailComponent() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const navigate = useNavigate();
    const { channel, signalId } = useParams();
    const [signal, setSignal] = React.useState<SignalDetail | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);

        const endpoint = new URL(`/signal/${channel}/${signalId}`, apiURL).href;
        fetchWithAuth(endpoint)
            .then(handleHTTPResponse)
            .then((data) => {
                // Convert all string to proper date objects
                data.metadata.date = new Date(data.metadata.date);
                data.prices = data.prices.map((item: Price) => ({
                    ...item,
                    time: new Date(item.time),
                }));
                data.orders = data.orders.map((order: Order) => ({
                    ...order,
                    time: new Date(order.time),
                    updateTime: new Date(order.updateTime),
                }));
                data.income = data.income.map(
                    (income: Income, index: number) => ({
                        ...income,
                        id: index + 1,
                        time: new Date(income.time),
                    }),
                );

                setSignal(data);
            })
            .catch(handleHTTPError)
            .finally(() => setLoading(false));
    }, []);

    let component = null;

    if (loading) {
        component = (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress disableShrink />
            </Box>
        );
    } else if (!loading && signal) {
        component = (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} md={3} paddingRight={isMobile ? 0 : 2}>
                        <Information metadata={signal.metadata} />
                        <Metrics income={signal.income} />
                    </Grid>
                    <Grid item xs={12} md={9} marginTop={isMobile ? 2 : 0}>
                        <Chart
                            metadata={signal.metadata}
                            prices={signal.prices}
                        />
                    </Grid>
                </Grid>
                <OrderTable orders={signal.orders} />
                <Grid container>
                    <Grid item xs={12} md={6} paddingRight={isMobile ? 0 : 2}>
                        <IncomeTable income={signal.income} />
                    </Grid>
                    <Grid item xs={12} md={6} marginTop={isMobile ? 2 : 0}>
                        <ExecutionChart trades={signal.trades} />
                    </Grid>
                </Grid>
                <Logs metadata={signal.metadata} />
            </React.Fragment>
        );
    }

    return (
        <Stack direction="column" spacing={2}>
            <Button variant="contained" onClick={() => navigate(-1)}>
                Back to previous page
            </Button>
            {component}
        </Stack>
    );
}
