import React from "react";

import { useNavigate, useRoutes } from "react-router-dom";

import TelegramSignals from "pages/telegram/signals";
import SignalDetail from "pages/telegram/signals/details";
import Portfolio from "pages/portfolio";
import Layout from "layout";
import Dashboard from "pages/dashboard";
import Login from "pages/login";

const telegramRoutes = {
    path: "/signal",
    element: <Layout />,
    children: [
        {
            path: "",
            element: <TelegramSignals />,
        },
        {
            path: ":channel/:signalId",
            element: <SignalDetail />,
        },
    ],
};

const portfolioRoutes = {
    path: "/portfolio",
    element: <Layout />,
    children: [
        {
            path: "",
            element: <Portfolio />,
        },
    ],
};

const dashboardRoutes = {
    path: "/",
    element: <Layout />,
    children: [
        {
            path: "",
            element: <Dashboard />,
        },
    ],
};

const appRoutes = {
    path: "/login",
    element: <Login />,
};

export default function Routes() {
    const navigate = useNavigate();

    React.useEffect(() => {
        // Redirect to login if no auth token
        if (!localStorage.getItem("authToken")) {
            navigate("/login");
        }
    }, []);

    return useRoutes([
        telegramRoutes,
        portfolioRoutes,
        dashboardRoutes,
        appRoutes,
    ]);
}
