import React from "react";

import { Channel } from "store";

import { apiURL } from "config";
import { Signal, setSignals } from "store";
import { fetchWithAuth, handleHTTPError, handleHTTPResponse } from "utilities";
import { useNavigate } from "react-router-dom";

function useTelegramSignals(channel: Channel): [Signal[], boolean, () => void] {
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const navigate = useNavigate();

    const loadSignals = function (channel: Channel) {
        setLoading(true);

        const endpoint = new URL(`/signal/${channel.slug}/`, apiURL).href;
        fetchWithAuth(endpoint, navigate)
            .then(handleHTTPResponse)
            .then((data) => setSignals(channel, data))
            .catch(handleHTTPError)
            .finally(() => {
                setRefresh(false);
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (refresh || channel.signals.length <= 0) {
            loadSignals(channel);
        }
    }, [channel, refresh]);

    return [channel.signals, loading, () => setRefresh(true)];
}

export default useTelegramSignals;
