// ==============================|| OVERRIDES - BUTTON ||============================== //

import { Theme } from "@mui/material";

export default function Button(theme: Theme) {
    const disabledStyle = {
        "&.Mui-disabled": {
            backgroundColor: theme.palette.grey[200],
        },
    };

    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 400,
                },
                contained: {
                    ...disabledStyle,
                },
                outlined: {
                    ...disabledStyle,
                },
                containedPrimary: {
                    backgroundColor: "#556ee6",
                },
                containedSecondary: {
                    backgroundColor: "#74788d",
                },
                containedInfo: {
                    backgroundColor: "#50a5f1",
                    "&:hover": { backgroundColor: "#448ccd" },
                },
            },
        },
    };
}
