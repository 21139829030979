// assets
import { MessageOutlined } from "@ant-design/icons";

const telegram = {
    id: "group-telegram",
    title: "Telegram channels",
    type: "group",
    children: [
        {
            id: "telegram",
            title: "Signals",
            type: "item",
            url: "/signal",
            icon: MessageOutlined,
            breadcrumbs: false,
        },
    ],
};

export default telegram;
