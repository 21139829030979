import React from "react";
import {
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
    Box,
    Button,
    useMediaQuery,
    useTheme,
    CircularProgress,
} from "@mui/material";

import Logo from "../../components/Logo";
import { apiURL } from "config";
import { handleHTTPError } from "utilities";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Login() {
    const theme = useTheme();
    const navigate = useNavigate();
    const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState("");

    const handleSubmit = (event: React.FormEvent) => {
        setLoading(true);

        const endpoint = new URL("/authenticate", apiURL).href;
        const reqOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ password: password }),
        };

        fetch(endpoint, reqOptions)
            .then((response) => {
                if (response.status === 403) {
                    toast.error("Incorrect password", { theme: "colored" });
                } else if (!response.ok) {
                    toast.error(`Request failed (code: ${response.status})`, {
                        theme: "colored",
                    });
                } else {
                    const token = response.headers.get("X-Auth-Token");
                    if (token) {
                        localStorage.setItem("authToken", token);
                        navigate("/");
                    } else {
                        toast.error("Invalid token", { theme: "colored" });
                    }
                }
            })
            .catch(handleHTTPError)
            .finally(() => setLoading(false));
        event.preventDefault();
    };

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            width={breakpoint ? "90%" : "30%"}
            sx={{ margin: "auto" }}
        >
            <Grid item>
                <Stack
                    direction="row"
                    spacing={2}
                    marginTop={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Logo size={48} />
                    <Typography
                        variant="h2"
                        sx={{ textTransform: "uppercase" }}
                    >
                        Krypto
                    </Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Paper>
                    <Box component="form" onSubmit={handleSubmit}>
                        <Stack
                            direction="column"
                            spacing={2}
                            width="100%"
                            alignItems="center"
                        >
                            <TextField
                                size="small"
                                placeholder="Your password…"
                                type="password"
                                value={password}
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                                autoComplete="password"
                                fullWidth
                            />
                            {loading ? (
                                <CircularProgress
                                    sx={{ textAlign: "center" }}
                                    size={36}
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    color="info"
                                    sx={{ fontWeight: 600 }}
                                    type="submit"
                                    fullWidth
                                >
                                    Login
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
    return <p>Dashboard</p>;
}
