import React from "react";

import { apiURL } from "config";
import { fetchWithAuth, handleHTTPError, handleHTTPResponse } from "utilities";
import {
    CircularProgress,
    Container,
    Stack,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Grid,
    useTheme,
    useMediaQuery,
} from "@mui/material";

import Balance from "./Balance";
import Chart from "./Chart";
import { BalanceData, WalletData } from "./models";
import "./index.css";
import moment, { Moment } from "moment";

const intervals = [
    { label: "1M", startDate: moment().subtract(1, "month") },
    { label: "3M", startDate: moment().subtract(3, "months") },
    { label: "6M", startDate: moment().subtract(6, "months") },
    { label: "1Y", startDate: moment().subtract(1, "year") },
    { label: "YTD", startDate: moment().startOf("year") },
];

export default function Dashboard() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const [loading, setLoading] = React.useState(false);
    const [wallet, setWallet] = React.useState<WalletData>();
    const [startDate, setStartDate] = React.useState<Moment>(
        intervals[1].startDate,
    );
    let content;

    React.useEffect(() => {
        const loadWallet = () => {
            const endpoint = new URL("/wallet/", apiURL).href;

            setLoading(true);
            fetchWithAuth(endpoint)
                .then((res) => handleHTTPResponse(res))
                .then((response) => {
                    // Ffill balance data
                    const balance: BalanceData[] = [];
                    let prev = null;

                    for (const item of response.data) {
                        if (item.balance == 0 && prev) {
                            balance.push({
                                ...item,
                                balance: prev,
                            });
                        } else {
                            balance.push(item);
                            prev = item.balance;
                        }
                    }

                    setWallet({
                        ...response,
                        data: balance,
                        last_update: new Date(response.last_update),
                    });
                })
                .catch((err) => handleHTTPError(err))
                .finally(() => setLoading(false));
        };

        loadWallet();
    }, []);

    const handleChange = (_: React.MouseEvent<HTMLElement>, value: string) => {
        const startDate = intervals.find((v) => v.label === value);
        if (startDate) {
            setStartDate(startDate.startDate);
        }
    };

    if (loading) {
        content = (
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <CircularProgress />
            </Container>
        );
    } else if (!loading && wallet) {
        content = (
            <Grid
                container
                direction={isMobile ? "column" : "row"}
                flexWrap="nowrap"
                spacing={2}
                width="100%"
                padding={0}
                sx={{
                    "&>.MuiGrid-item": {
                        paddingLeft: isMobile ? 0 : 2,
                    },
                }}
            >
                <Grid item xs={12} md={3} padding={0}>
                    <Balance startDate={startDate} wallet={wallet} />
                </Grid>
                <Grid item xs={12} md={9} padding={0}>
                    <Chart startDate={startDate} wallet={wallet} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Stack spacing={2} sx={{ height: "100%" }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3">Dashboard</Typography>
                {true && (
                    <ToggleButtonGroup
                        exclusive
                        value={
                            intervals.find((v) => v.startDate === startDate)
                                ?.label
                        }
                        size="small"
                        className="toggle-group"
                        onChange={handleChange}
                    >
                        {intervals.map((interval) => (
                            <ToggleButton
                                key={interval.label}
                                value={interval.label}
                            >
                                {interval.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                )}
            </Stack>
            {content}
        </Stack>
    );
}
