import React from "react";

import {
    Typography,
    Paper,
    Stack,
    CircularProgress,
    Box,
    Chip,
    IconButton,
    Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Channel, Signal } from "store";
import useTelegramSignals from "hooks/signal";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { generateSignalID } from "utilities";
import { useNavigate } from "react-router-dom";

interface Props {
    channel: Channel;
    returns: boolean;
}

const formatPrice = (n: number | string): string => {
    if (!n) {
        return "-";
    }

    const number = Number(n);
    if (number > 100) {
        return number.toFixed(1);
    } else if (number > 5) {
        return number.toFixed(2);
    } else if (number > 1) {
        return number.toFixed(3);
    } else if (number > 0.1) {
        return number.toFixed(4);
    } else {
        return number.toFixed(5);
    }
};

const getEntryPrice = (signal: Signal): string => {
    if (signal.side == "LONG") {
        return signal.entries[signal.entries.length - 1];
    }
    return signal.entries[0];
};

const formatPct = (target: string, price: string): string => {
    if (!price || !target) {
        return "-";
    }

    const pct = (Number(price) - Number(target)) / Number(target);
    return Math.abs(pct * 100).toFixed(2) + "%";
};

const SignalTable = function (props: Props) {
    const [signals, loading, refresh] = useTelegramSignals(props.channel);
    const navigate = useNavigate();
    const columns: GridColDef[] = [
        { headerName: "#", field: "id", width: 30 },
        { headerName: "Symbol", field: "symbol", width: 100 },
        {
            headerName: "Date",
            field: "date",
            width: 200,
            valueFormatter(params) {
                return params.value.toLocaleString();
            },
        },
        {
            headerName: "Side",
            field: "side",
            width: 100,
            renderCell(params) {
                return (
                    <Chip
                        label={params.value}
                        color={params.value == "LONG" ? "success" : "error"}
                    />
                );
            },
        },
        {
            headerName: "Entry",
            field: "entries",
            width: 100,
            valueGetter(params) {
                return formatPrice(getEntryPrice(params.row));
            },
        },
        ...[0, 1, 2, 3].map((index: number): GridColDef => {
            return {
                headerName: (index <= 0 ? "Profit #" : "#") + (index + 1),
                field: `profit_${index}`,
                width: 100,
                valueGetter(params) {
                    const price = params.row.profits[index];
                    if (!price) {
                        return "-";
                    }
                    return props.returns
                        ? formatPct(getEntryPrice(params.row), price)
                        : formatPrice(price);
                },
            };
        }),
        {
            headerName: "Stop loss",
            field: "stop_loss",
            width: 100,
            valueGetter(params) {
                return props.returns
                    ? "-" + formatPct(getEntryPrice(params.row), params.value)
                    : formatPrice(params.value);
            },
        },
    ];

    const handleRefresh = () => {
        refresh();
    };

    return (
        <Paper>
            <Stack direction="column">
                <Stack
                    direction="row"
                    alignItems="end"
                    justifyContent="space-between"
                >
                    <Typography variant="h4">{props.channel.name}</Typography>
                    <Tooltip title="Refresh" placement="top">
                        <IconButton
                            aria-label="refresh"
                            size="small"
                            onClick={handleRefresh}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress disableShrink />
                    </Box>
                ) : (
                    <DataGrid
                        rows={signals}
                        columns={columns}
                        pageSizeOptions={[10, 20, 50]}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        sortModel={[
                            {
                                field: "id",
                                sort: "desc",
                            },
                        ]}
                        rowSpacingType="border"
                        onRowClick={(params) => {
                            const identifier = generateSignalID(
                                props.channel,
                                params.row,
                            );
                            navigate(
                                `/signal/${props.channel.slug}/${identifier}`,
                            );
                        }}
                    />
                )}
            </Stack>
        </Paper>
    );
};

export default SignalTable;
