import React from "react";

import {
    Typography,
    Paper,
    Chip,
    ListItem,
    ListItemText,
    List,
    ListItemTextProps,
} from "@mui/material";

import { formatPrice } from "formats";
import "./Information.css";
import { Metadata } from "./model";

interface Props {
    metadata: Metadata;
}

const ListItemHeader = (props: ListItemTextProps) => (
    <ListItemText
        {...props}
        primaryTypographyProps={{ fontWeight: "bold" }}
        sx={{ width: "30%" }}
    />
);

const ListItemValue = (props: ListItemTextProps) => (
    <ListItemText
        {...props}
        sx={{
            textAlign: "right",
            width: "70%",
        }}
        primaryTypographyProps={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }}
    />
);

const Information = function (props: Props) {
    if (!props.metadata) {
        return <p>test</p>;
    }
    return (
        <Paper>
            <Typography variant="h4">Signal info</Typography>
            <List dense disablePadding className="info-list">
                <ListItem disablePadding>
                    <ListItemHeader>Channel</ListItemHeader>
                    <ListItemValue>{props.metadata.channel}</ListItemValue>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemHeader>Symbol</ListItemHeader>
                    <ListItemValue>{props.metadata.symbol}</ListItemValue>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemHeader>Posted at</ListItemHeader>
                    <ListItemValue>
                        {new Date(props.metadata.date).toLocaleString()}
                    </ListItemValue>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemHeader>Side</ListItemHeader>
                    <ListItemValue>
                        <Chip
                            label={props.metadata.side}
                            color={
                                props.metadata.side == "LONG"
                                    ? "success"
                                    : "error"
                            }
                        />
                    </ListItemValue>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemHeader>Entries</ListItemHeader>
                    <ListItemValue>
                        {props.metadata.entries.map(formatPrice).join(" - ")}
                    </ListItemValue>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemHeader>Profits</ListItemHeader>
                    <ListItemValue>
                        {props.metadata.profits.map(formatPrice).join(" - ")}
                    </ListItemValue>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemHeader>Stop loss</ListItemHeader>
                    <ListItemValue>
                        {formatPrice(props.metadata.stop_loss)}
                    </ListItemValue>
                </ListItem>
            </List>
        </Paper>
    );
};

export default Information;
