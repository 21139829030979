import React from "react";

import {
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Table,
    styled,
    Stack,
    CircularProgress,
} from "@mui/material";
import { apiURL } from "config";
import { fetchWithAuth, handleHTTPError, handleHTTPResponse } from "utilities";
import { Metadata } from "./model";
import moment from "moment";

interface Props {
    metadata: Metadata;
}

type Log = {
    level: string;
    message: string;
    timestamp: Date;
    fields: Record<string, string>;
};

const Cell = styled(TableCell)({
    padding: 0,
    fontFamily: "Source Code Pro",
});

export default function Logs(props: Props) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [logs, setLogs] = React.useState<Log[]>([]);

    React.useEffect(() => {
        const startTime = new Date(props.metadata.date);
        const endTime = new Date(props.metadata.date);

        // Get the logs for 1hr when the signal has been posted
        startTime.setMinutes(startTime.getMinutes() - 1);
        endTime.setHours(endTime.getHours() + 1);

        const params = new URLSearchParams({
            start_time: startTime.toISOString(),
            end_time: endTime.toISOString(),
        });
        const endpoint = new URL(`/logs/?${params}`, apiURL).href;

        setLoading(true);
        fetchWithAuth(endpoint)
            .then(handleHTTPResponse)
            .then((data: Log[]) => {
                setLogs(data);
            })
            .catch(handleHTTPError)
            .finally(() => setLoading(false));
    }, []);

    return (
        <Paper>
            <Typography variant="h4">Logs</Typography>
            {loading ? (
                <Stack alignItems="center">
                    <CircularProgress />
                </Stack>
            ) : (
                <Table sx={{ fontFamily: "Source Code Pro" }}>
                    <TableBody>
                        {logs.map((log, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:nth-child(even)": {
                                        backgroundColor: "#ececec",
                                    },
                                }}
                            >
                                <Cell width={180}>
                                    {moment(log.timestamp).format(
                                        "YYYY-MM-DD HH:mm:ss.SSS",
                                    )}
                                </Cell>
                                <Cell width={80}>{log.level}</Cell>
                                <Cell>{log.message}</Cell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </Paper>
    );
}
