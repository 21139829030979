import React from "react";

import { Paper, Typography, Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { Order } from "./model";
import { formatPrice } from "formats";

interface Props {
    orders: Order[];
}

export default function OrderTable(props: Props) {
    const columns: GridColDef[] = [
        { headerName: "#", field: "orderId", flex: 0.7 },
        {
            headerName: "Type",
            field: "type",
            flex: 1,
            renderCell(params) {
                const color = (function () {
                    switch (params.value) {
                        case "LIMIT":
                            return "warning";
                        case "TAKE_PROFIT_MARKET":
                            return "success";
                        case "STOP_MARKET":
                            return "error";
                    }
                })();
                return <Chip label={params.value} color={color} />;
            },
        },
        {
            headerName: "Side",
            field: "side",
            flex: 0.5,
            renderCell(params) {
                return (
                    <Chip
                        label={params.value}
                        color={params.value == "BUY" ? "success" : "error"}
                    />
                );
            },
        },
        {
            headerName: "Time",
            field: "time",
            flex: 1.2,
            valueFormatter(params) {
                return params.value.toLocaleString();
            },
        },
        {
            headerName: "Status",
            field: "status",
            flex: 1,
            renderCell(params) {
                const color = (function () {
                    switch (params.value) {
                        case "FILLED":
                            return "success";
                        case "CANCELED":
                            return "error";
                    }
                })();
                return <Chip label={params.value} color={color} />;
            },
        },
        {
            headerName: "Price",
            field: "avgPrice",
            flex: 0.7,
            valueGetter(record) {
                if (record.row.avgPrice == 0) {
                    return record.row.stopPrice;
                }
                return record.row.avgPrice;
            },
            valueFormatter(params) {
                return formatPrice(params.value);
            },
        },
        {
            headerName: "Quantity",
            field: "quantity",
            flex: 0.7,
            valueGetter(record) {
                if (record.row.executedQty > 0) {
                    return record.row.executedQty;
                }
                return record.row.origQty;
            },
        },
        {
            headerName: "Amount",
            field: "cumQuote",
            flex: 0.7,
            valueFormatter(params) {
                return formatPrice(params.value);
            },
        },
        {
            headerName: "Updated time",
            field: "updateTime",
            flex: 1.2,
            valueFormatter(params) {
                return params.value.toLocaleString();
            },
        },
    ];
    return (
        <Paper>
            <Typography variant="h4">Orders</Typography>
            {props.orders.length > 0 ? (
                <DataGrid
                    getRowId={(record: Order) => {
                        return record.orderId;
                    }}
                    rows={props.orders}
                    columns={columns}
                    pageSizeOptions={[5, 10]}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    sortModel={[
                        {
                            field: "time",
                            sort: "asc",
                        },
                    ]}
                    rowSpacingType="border"
                />
            ) : (
                <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                    No data
                </Typography>
            )}
        </Paper>
    );
}
