import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery, useTheme, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import Drawer from "./Drawer";

const Layout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const [open, setOpen] = useState(!isMobile);

    const toggleDrawer = (open: boolean) => {
        setOpen(open);
    };

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            {isMobile && (
                <Button
                    variant="contained"
                    onClick={() => toggleDrawer(true)}
                    sx={{
                        position: "fixed",
                        zIndex: 100,
                        top: ".5em",
                        left: ".5em",
                        minWidth: 36,
                        width: 36,
                        height: 36,
                        color: "#3b3e66",
                        margin: "0.3em",
                        background: "hsla(0,0%,100%,.8)",
                        boxShadow:
                            "0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.045454545454545456)," +
                            " 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.022727272727272728)," +
                            " 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.045454545454545456)," +
                            " 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.022727272727272728)",
                        borderRadius: "10px",
                    }}
                >
                    <MenuIcon />
                </Button>
            )}
            <Drawer open={open} handleDrawerToggle={toggleDrawer} />
            <Box
                component="main"
                sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout;
