import { Theme } from "@mui/material";

export default function Drawer(theme: Theme) {
    const transition = theme.transitions.create(["all"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    });
    const textColor = "#a6b0cf";

    return {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    padding: 0,
                    backgroundColor: "#2a3042",
                    width: "250px",

                    "& h2.MuiTypography-root": {
                        color: "#6a7187",
                        fontSize: 11,
                        fontWeight: 600,
                        textTransform: "uppercase",
                        padding: "12px 20px",
                    },

                    "& .MuiListItemButton-root": {
                        color: textColor,
                        padding: "0.625rem 1.5rem",

                        "&, & .MuiListItemIcon-root": {
                            transition,
                        },

                        "&:hover, &:hover .MuiListItemIcon-root": {
                            color: "white",
                            background: "transparent",
                            transition,
                        },
                    },

                    "& .MuiListItemIcon-root": {
                        color: textColor,
                        minWidth: "2rem",
                    },
                },
            },
        },
    };
}
