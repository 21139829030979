import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "@fontsource/poppins";
import "@fontsource/poppins/600.css";
import "@fontsource/source-code-pro";

import reportWebVitals from "./reportWebVitals";
import ThemeCustomization from "./theme";
import Routes from "./routes";

import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeCustomization>
                <ToastContainer />
                <Routes />
            </ThemeCustomization>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
