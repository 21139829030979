import React from "react";

import { Link } from "react-router-dom";
import {
    Drawer as MuiDrawer,
    Stack,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
} from "@mui/material";

import Navigation from "./Navigation";
import Logo from "../components/Logo";
import { drawerWidth } from "config";

interface Props {
    open: boolean;
    handleDrawerToggle: (open: boolean) => void;
}

const Drawer = function (props: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    const handleClose = (_: React.MouseEvent, reason: string) => {
        if (reason === "backdropClick") {
            props.handleDrawerToggle(false);
        }
    };

    return (
        <Box
            component="nav"
            minWidth={isMobile ? 0 : drawerWidth}
            sx={{ display: isMobile && !open ? "none" : "block" }}
        >
            <MuiDrawer
                variant={isMobile ? "temporary" : "permanent"}
                open={props.open}
                onClose={handleClose}
            >
                <Link to="/">
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ margin: "32px 0" }}
                    >
                        <Logo size={26} />
                        <Typography
                            variant="h1"
                            sx={{
                                fontWeight: 700,
                                textTransform: "uppercase",
                                fontSize: 20,
                                color: "white",
                            }}
                        >
                            Krypto
                        </Typography>
                    </Stack>
                </Link>
                <Navigation />
            </MuiDrawer>
        </Box>
    );
};

export default Drawer;
