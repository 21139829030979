const transition =
    "color .15s ease-in-out," +
    "background-color .15s ease-in-out," +
    "border-color .15s ease-in-out," +
    "box-shadow .15s ease-in-out";

export default function ToggleButton() {
    return {
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: ".25rem",
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: "#74788d",
                    borderColor: "#74788d",
                    "&:hover": {
                        transition: transition,
                        color: "white",
                        backgroundColor: "#74788d",
                    },
                    "&.Mui-selected": {
                        backgroundColor: "#74788d",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#636678",
                        },
                    },
                    transition: transition,
                },
            },
        },
    };
}
