import React from "react";

import { Box, Typography, List } from "@mui/material";

import NavItem from "./NavItem";
import menuItem, { GroupItem, MenuItem } from "menu-items";

export default function Navigation() {
    const navGroups = menuItem.items.map((item: GroupItem) => {
        switch (item.type) {
            case "group":
                return (
                    <List
                        key={item.title}
                        subheader={
                            item.title && (
                                <Typography variant="h2">
                                    {item.title}
                                </Typography>
                            )
                        }
                    >
                        {item.children.map((menuItem: MenuItem) => {
                            return (
                                <NavItem
                                    key={menuItem.id}
                                    item={menuItem}
                                    level={1}
                                />
                            );
                        })}
                    </List>
                );
        }
    });

    return <Box>{navGroups}</Box>;
}
