import React from "react";

import { Paper, Grid, Typography } from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import { Income } from "./model";

interface Props {
    income: Income[];
}

export default function Metrics(props: Props) {
    const sumIncomeType = (incomeType: string) => {
        return props.income.reduce<number>((acc, value) => {
            if (value.incomeType === incomeType) {
                return acc + value.income;
            }
            return acc;
        }, 0);
    };

    const realizedPNL = sumIncomeType("REALIZED_PNL");
    const commission = sumIncomeType("COMMISSION");
    const fundingFee = sumIncomeType("FUNDING_FEE");

    return (
        <Paper sx={{ marginTop: 2 }}>
            <Grid container>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <SavingsIcon
                        sx={{
                            fontSize: 48,
                            color: "#556ee7",
                        }}
                    />
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                        Realized PNL
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: realizedPNL < 0 ? "#f46a6a" : "#34c38f",
                        }}
                    >
                        {"$" + realizedPNL.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <AccountBalanceIcon
                        sx={{
                            fontSize: 48,
                            color: "#556ee7",
                        }}
                    />
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                        Commission
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: commission < 0 ? "#f46a6a" : "#34c38f",
                        }}
                    >
                        {"$" + commission.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <CurrencyExchangeIcon
                        sx={{
                            fontSize: 48,
                            color: "#556ee7",
                        }}
                    />
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                        Funding Fee
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: fundingFee < 0 ? "#f46a6a" : "#34c38f",
                        }}
                    >
                        {"$" + fundingFee.toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}
