export default function CircularProgress() {
    return {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#343a40",
                },
            },
        },
    };
}
