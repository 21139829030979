// third-party
import { merge } from "lodash";

// project import
// import Badge from './Badge';
import Button from "./Button";
// import CardContent from './CardContent';
// import Checkbox from './Checkbox';
// import Chip from './Chip';
// import IconButton from './IconButton';
// import InputLabel from './InputLabel';
// import LinearProgress from './LinearProgress';
// import Link from './Link';
// import ListItemIcon from './ListItemIcon';
// import OutlinedInput from './OutlinedInput';
// import Tab from './Tab';
// import TableCell from './TableCell';
// import Tabs from './Tabs';
import Link from "./Link";
import Drawer from "./Drawer";
import Typography from "./Typography";
import Paper from "./Paper";
import CircularProgress from "./CircularProgress";
import DataGrid from "./DataGrid";
import Chip from "./Chip";
import TextField from "./TextField";
import { Theme } from "@mui/material";
import Grid from "./Grid";
import ToggleButton from "./ToggleButton";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
    return merge(
        Drawer(theme),
        Button(theme),
        Typography(),
        Paper(theme),
        CircularProgress(),
        DataGrid(),
        Chip(),
        Grid(),
        ToggleButton(),
        // Badge(theme),
        // CardContent(),
        // Checkbox(theme),
        // Chip(theme),
        // IconButton(theme),
        // InputLabel(theme),
        // LinearProgress(),
        Link(),
        TextField(),
        // ListItemIcon(),
        // OutlinedInput(theme),
        // Tab(theme),
        // TableCell(theme),
        // Tabs(),
        // Typography()
    );
}
