export default function TextField() {
    return {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ced4da",
                    },
                    "&:hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ced4da",
                        },
                    },
                    "&.Mui-focused": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ced4da",
                            borderWidth: 1,
                        },
                    },
                },
            },
        },
    };
}
