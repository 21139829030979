import React from "react";

import { Link } from "react-router-dom";

// material-ui
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { MenuItem } from "menu-items";
// project import
// import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
    item: MenuItem;
    level: number;
}

export default function NavItem(props: Props) {
    //   const dispatch = useDispatch();
    //   const { pathname } = useLocation();

    const drawerOpen = true;

    //   const { drawerOpen, openItem } = useSelector((state) => state.menu);

    const listItemProps = {
        component: React.forwardRef<HTMLAnchorElement>((itemProps, ref) => (
            <Link ref={ref} {...itemProps} to={props.item.url} />
        )),
    };

    //   const itemHandler = (id) => {
    //     dispatch(activeItem({ openItem: [id] }));
    //   };

    const Icon = props.item.icon;
    const itemIcon = props.item.icon ? (
        <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
    ) : (
        false
    );

    //   const isSelected = openItem.findIndex((id) => id === item.id) > -1;
    //   // active menu item on page load
    //   useEffect(() => {
    //     if (pathname.includes(item.url)) {
    //       dispatch(activeItem({ openItem: [item.id] }));
    //     }
    //     // eslint-disable-next-line
    //   }, [pathname]);

    return (
        <ListItemButton disableRipple {...listItemProps}>
            {itemIcon && <ListItemIcon>{itemIcon}</ListItemIcon>}
            {(drawerOpen || (!drawerOpen && props.level !== 1)) && (
                <ListItemText disableTypography sx={{ fontSize: 13 }}>
                    {props.item.title}
                </ListItemText>
            )}
        </ListItemButton>
    );
}
