// assets
import { DashboardOutlined, WalletOutlined } from "@ant-design/icons";

const dashboard = {
    id: "group-dashboard",
    title: "Navigation",
    type: "group",
    children: [
        {
            id: "dashboard",
            title: "Dashboard",
            type: "item",
            url: "/",
            icon: DashboardOutlined,
            breadcrumbs: false,
        },
        {
            id: "portfolio",
            title: "Portfolio",
            type: "item",
            url: "/portfolio",
            icon: WalletOutlined,
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
