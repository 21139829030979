import React from "react";
import { Paper, Typography } from "@mui/material";

import { Trade } from "./model";
import Plot from "react-plotly.js";

interface Props {
    trades: Trade[];
}

export default function Chart(props: Props) {
    const index: Date[] = [];
    const prices: number[] = [];
    const cumVolume: number[] = [];

    for (const trade of props.trades) {
        if (cumVolume.length > 0) {
            cumVolume.push(
                cumVolume[cumVolume.length - 1] + trade.price * trade.quantity,
            );
        } else {
            cumVolume.push(trade.price * trade.quantity);
        }

        prices.push(trade.price);
        index.push(new Date(trade.time));
    }

    return (
        <Paper>
            <Typography variant="h4">Execution</Typography>
            <Plot
                data={[
                    {
                        x: index,
                        y: prices,
                        type: "scatter",
                        name: "Price",
                        line: { color: "#50a5f1" },
                    },
                    {
                        x: index,
                        y: cumVolume,
                        type: "scatter",
                        name: "Volume",
                        yaxis: "y2",
                        line: { color: "#343a40" },
                    },
                ]}
                layout={{
                    xaxis: { title: "Time" },
                    yaxis: {
                        title: "<b>Price</b> <i style='font-size: 11px'>(in USDT)</i>",
                        zeroline: false,
                    },
                    yaxis2: {
                        title: "<b>Aggregated Volume</b> <i style='font-size: 11px'>(in USDT)</i>",
                        overlaying: "y",
                        side: "right",
                        zeroline: false,
                    },
                    showlegend: false,
                    margin: { b: 60, l: 60, r: 60, t: 0 },
                }}
                style={{ width: "100%", height: "100%", maxHeight: "300px" }}
            />
        </Paper>
    );
}
