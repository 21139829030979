export default function Typography() {
    return {
        MuiTypography: {
            styleOverrides: {
                h3: {
                    fontSize: 16,
                    fontWeight: 600,
                    textTransform: "uppercase" as const,
                    color: "#343a40",
                },
                h4: {
                    fontSize: 15,
                    fontWeight: 600,
                    margin: "0 0 7px",
                },
                body2: {
                    color: "#74788d",
                    fontSize: ".8125rem",
                },
            },
        },
    };
}
