export const formatPrice = (n: number | string): string => {
    if (!n) {
        return "-";
    }

    const number = Number(n);
    if (number == 0) {
        return number.toFixed(0);
    } else if (number > 100) {
        return number.toFixed(1);
    } else if (number > 5) {
        return number.toFixed(2);
    } else if (number > 1) {
        return number.toFixed(3);
    } else if (number > 0.1) {
        return number.toFixed(4);
    } else {
        return number.toFixed(5);
    }
};
