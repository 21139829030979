import React from "react";
import Plot from "react-plotly.js";
import { Paper, Typography } from "@mui/material";
import { Metadata, Price } from "./model";
import { Data } from "plotly.js";

interface Props {
    metadata: Metadata;
    prices: Price[];
}

export default function Chart(props: Props) {
    const index: Date[] = [];
    const prices: number[] = [];
    const returns: number[] = [];
    const entryPrice =
        props.metadata.side == "LONG"
            ? props.metadata.entries[props.metadata.entries.length - 1]
            : props.metadata.entries[0];
    let prevPrice: number | null = null;
    let cumReturns = 0.0;
    let maxPrice = props.prices.length > 0 ? props.prices[0].price : 0.0;

    for (const item of props.prices) {
        if (prevPrice) {
            cumReturns += ((item.price - prevPrice) / prevPrice) * 100;
        }

        index.push(new Date(item.time));
        prices.push(item.price);
        returns.push(cumReturns);
        prevPrice = item.price;

        if (props.metadata.side == "LONG") {
            maxPrice = item.price > maxPrice ? item.price : maxPrice;
        } else {
            maxPrice = item.price < maxPrice ? item.price : maxPrice;
        }
    }

    const annotations = [
        {
            x: +index[Math.ceil(index.length / 2)],
            y: entryPrice,
            text: "Entry price",
            showarrow: false,
            yshift: 10,
            font: { color: "#343a40" },
        },
        {
            x: +index[Math.ceil(index.length / 2)],
            y: props.metadata.stop_loss,
            text: "Stop loss",
            showarrow: false,
            yshift: 10,
            font: { color: "#f46a6a" },
        },
    ];

    const profits: Data[] = [];
    for (let i = 0; i < props.metadata.profits.length; i++) {
        const target = props.metadata.profits[i];

        profits.push({
            x: index,
            y: Array(index.length).fill(target),
            type: "scatter",
            name: `Profit ${i + 1}`,
            line: { dash: "dash", width: 1, color: "#34c38f" },
            opacity: 0.8,
        });
        annotations.push({
            x: +index[Math.ceil(index.length / 2)],
            y: target,
            text: `Profit ${i + 1}`,
            showarrow: false,
            yshift: 10,
            font: { color: "#34c38f" },
        });

        if (props.metadata.side == "LONG" && maxPrice < target) {
            break;
        } else if (props.metadata.side == "SHORT" && target < maxPrice) {
            break;
        }
    }

    return (
        <Paper>
            <Typography variant="h4">Prices</Typography>
            <Plot
                data={[
                    {
                        x: index,
                        y: prices,
                        type: "scatter",
                        name: "Price",
                        line: { color: "#50a5f1" },
                    },
                    {
                        x: index,
                        y: returns,
                        type: "scatter",
                        name: "Returns",
                        yaxis: "y2",
                        opacity: 0,
                    },
                    {
                        x: index,
                        y: Array(index.length).fill(entryPrice),
                        type: "scatter",
                        name: "Entry",
                        line: { dash: "dash", width: 1, color: "#343a40" },
                        opacity: 0.8,
                    },
                    {
                        x: index,
                        y: Array(index.length).fill(props.metadata.stop_loss),
                        type: "scatter",
                        name: "Stop loss",
                        line: { dash: "dash", width: 1, color: "#f46a6a" },
                        opacity: 0.8,
                    },
                    ...profits,
                ]}
                layout={{
                    xaxis: { title: "Time" },
                    yaxis: {
                        title: "<b>Price</b> <i style='font-size: 11px'>(in USDT)</i>",
                        zeroline: false,
                    },
                    yaxis2: {
                        title: "<b>Returns</b> <i style='font-size: 11px'>(in %)</i>",
                        overlaying: "y",
                        side: "right",
                        zeroline: false,
                    },
                    showlegend: false,
                    margin: { b: 60, l: 60, r: 60, t: 0 },
                    annotations: annotations,
                }}
                style={{ width: "100%", height: "100%" }}
            />
        </Paper>
    );
}
