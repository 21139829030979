import React from "react";

import { apiURL } from "config";
import { useStore, setChannels, Store } from "store";

import { fetchWithAuth, handleHTTPError, handleHTTPResponse } from "utilities";
import { useNavigate } from "react-router-dom";

export type Channel = {
    name: string;
    slug: string;
};

export default function useTelegramChannels() {
    const channels = useStore((store: Store) => store.channels);
    const navigate = useNavigate();

    React.useEffect(() => {
        // Don't refresh channels list if it has been done already
        if (channels.length > 0) {
            return;
        }

        const endpoint = new URL("/channel/", apiURL).href;
        fetchWithAuth(endpoint, navigate)
            .then((res) => handleHTTPResponse(res))
            .then((channels) => {
                setChannels(channels);
            })
            .catch((err) => handleHTTPError(err));
    }, []);

    return channels;
}
