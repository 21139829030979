import React from "react";

import Plot from "react-plotly.js";

import { Paper } from "@mui/material";
import { WalletData } from "./models";
import moment, { Moment } from "moment";

interface Props {
    startDate: Moment;
    wallet: WalletData;
}

export default function Chart(props: Props) {
    const index: Date[] = [];
    const pnl: number[] = [];
    const profits: number[] = [];

    for (let i = 1; i < props.wallet.data.length; i++) {
        const balance = props.wallet.data[i];
        const date = moment(balance.date);

        if (date.isSameOrAfter(props.startDate)) {
            index.push(date.toDate());
            profits.push(balance.profit);

            const ytd = props.wallet.data[i - 1].balance;
            const today = props.wallet.data[i].balance;
            const change = (today - ytd) / ytd;
            if (pnl.length <= 0) {
                pnl.push(change);
            } else {
                pnl.push(pnl[pnl.length - 1] + change);
            }
        }
    }

    return (
        <Paper>
            <Plot
                data={[
                    {
                        x: index,
                        y: pnl,
                        type: "scatter",
                        name: "P&L",
                        line: { color: "#50a5f1" },
                    },
                    {
                        x: index,
                        y: profits,
                        type: "bar",
                        name: "P&L",
                        yaxis: "y2",
                        marker: {
                            color: profits.map((p) =>
                                p < 0 ? "#f46a6a" : "#34c38f",
                            ),
                            opacity: 0.4,
                        },
                    },
                ]}
                layout={{
                    xaxis: { title: "Time" },
                    yaxis: {
                        title: "<b>P&L</b>",
                        zeroline: false,
                        tickformat: ",.2%",
                    },
                    yaxis2: {
                        title: "<b>Profits</b>",
                        overlaying: "y",
                        side: "right",
                        zeroline: false,
                        tickformat: "$",
                    },
                    showlegend: false,
                    margin: { b: 60, l: 60, r: 60, t: 0 },
                }}
                style={{ width: "100%", height: "100%" }}
            />
        </Paper>
    );
}
