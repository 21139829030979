export default function Link() {
    return {
        MuiLink: {
            defaultProps: {
                color: "red",
                underline: "hover" as const,
            },
        },
    };
}
