import React from "react";

import { Chip, Paper, Stack, Typography } from "@mui/material";
import moment, { Moment } from "moment";

import { WalletData } from "./models";

interface Props {
    startDate: Moment;
    wallet: WalletData;
}

const pctChange = (a: number, b: number): number => {
    return ((a - b) / b) * 100;
};

export default function Balance(props: Props) {
    const lastBalance = props.wallet.data[props.wallet.data.length - 1].balance;
    const firstBalance = props.wallet.data.find((v) =>
        moment(v.date).isSameOrAfter(props.startDate),
    )?.balance;
    const performance =
        firstBalance && lastBalance
            ? pctChange(lastBalance, firstBalance)
            : 0.0;
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    // Show performance of yesterday
    const yesterday = props.wallet.data[props.wallet.data.length - 2];
    const dayBefore = props.wallet.data[props.wallet.data.length - 3];
    const change = pctChange(yesterday.balance, dayBefore.balance);

    return (
        <Paper>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">Balance</Typography>
                <Typography variant="body2">Performance</Typography>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-between"
                marginBottom={2}
            >
                <Typography variant="h3" fontSize={18}>
                    {formatter.format(props.wallet.balance)}
                </Typography>
                <Chip
                    label={
                        (performance > 0 ? "+" : "") +
                        performance.toFixed(2) +
                        "%"
                    }
                    variant="filled"
                    color={performance < 0 ? "error" : "success"}
                />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <div>
                    <Typography
                        sx={{
                            fontSize: "0.7em",
                            textAlign: "right",
                            color: "#74788d",
                            fontWeight: "bold",
                        }}
                    >
                        Yesterday:
                    </Typography>
                    <Chip
                        label={
                            (change > 0 ? "+" : "") + change.toFixed(2) + "%"
                        }
                        variant="filled"
                        color={change < 0 ? "error" : "success"}
                        size="small"
                    />
                </div>
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontSize: "0.7em",
                        textAlign: "right",
                        color: "#74788d",
                    }}
                >
                    <strong>From:</strong>{" "}
                    {props.startDate.format("YYYY-MM-DD")}
                    <br />
                    <strong>Last update:</strong>{" "}
                    {moment(props.wallet.last_update).fromNow()}
                </Typography>
            </Stack>
        </Paper>
    );
}
