import React from "react";

import { Stack, Typography, FormControlLabel, Switch } from "@mui/material";

import useTelegramChannels from "hooks/channel";
import SignalTable from "./SignalTable";
import { Channel } from "store";

export default function TelegramSignals() {
    const channels = useTelegramChannels();
    const [showReturns, setShowReturns] = React.useState(false);
    return (
        <Stack direction="column" spacing={2}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3">Telegram signals</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showReturns}
                            onChange={(event) =>
                                setShowReturns(event.target.checked)
                            }
                        />
                    }
                    label="Show returns"
                />
            </Stack>

            {channels.map((channel: Channel) => {
                return (
                    <SignalTable
                        key={channel.slug}
                        channel={channel}
                        returns={showReturns}
                    />
                );
            })}
        </Stack>
    );
}
