import React from "react";

import { Paper, Typography, Chip } from "@mui/material";

import { Income } from "./model";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface Props {
    income: Income[];
}

export default function IncomeTable(props: Props) {
    const columns: GridColDef[] = [
        {
            headerName: "#",
            field: "id",
            flex: 1,
        },
        {
            headerName: "Type",
            field: "incomeType",
            flex: 1,
            renderCell(params) {
                const color = (function () {
                    switch (params.value) {
                        case "COMMISSION":
                            return "error";
                        case "REALIZED_PNL":
                            return "success";
                        case "FUNDING_FEE":
                            return "warning";
                    }
                })();
                return <Chip label={params.value} color={color} />;
            },
        },
        {
            headerName: "Value",
            field: "income",
            flex: 1,
            renderCell(params) {
                const color = (function () {
                    switch (params.value) {
                        case "COMMISSION":
                            return "error";
                        case "REALIZED_PNL":
                            return "success";
                    }
                })();
                return <Chip label={params.value} color={color} />;
            },
        },
        {
            headerName: "Time",
            field: "time",
            flex: 1,
            valueFormatter(params) {
                return params.value.toLocaleString();
            },
        },
    ];

    return (
        <Paper>
            <Typography variant="h4">Income</Typography>
            {props.income.length > 0 ? (
                <DataGrid
                    rows={props.income}
                    columns={columns}
                    pageSizeOptions={[5, 10]}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    sortModel={[
                        {
                            field: "time",
                            sort: "asc",
                        },
                    ]}
                    rowSpacingType="border"
                />
            ) : (
                <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                    No data
                </Typography>
            )}
        </Paper>
    );
}
