export default function DataGrid() {
    return {
        MuiDataGrid: {
            defaultProps: {
                columnHeaderHeight: 44,
                rowHeight: 44,
                rowSpacingType: "border",
                rowSelection: false,
            },
            styleOverrides: {
                columnHeaderTitle: {
                    color: "#495057",
                    fontSize: ".8125rem",
                    fontWeight: 600,
                },
                withBorderColor: {
                    borderColor: "#f0f2f6",
                    borderWidth: 1,
                },
                cell: {
                    "&:focus": { outline: "none" },
                },
            },
        },
    };
}
