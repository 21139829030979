import moment from "moment";
import { toast } from "react-toastify";
import { Channel, Signal } from "store";
import md5 from "md5";
import { NavigateFunction } from "react-router-dom";

export const fetchWithAuth = (
    endpoint: string,
    navigate?: NavigateFunction,
    init?: RequestInit,
) => {
    const headers = init?.headers ? new Headers(init.headers) : new Headers();
    const token = localStorage.getItem("authToken");
    // const navigate = useNavigate();

    if (token) {
        headers.set("x-auth-token", token);
    }

    return fetch(endpoint, {
        ...init,
        headers: headers,
    }).then((response) => {
        if (response.status === 403) {
            localStorage.removeItem("authToken");
            if (navigate) {
                navigate("/login");
            }
            return Promise.reject(
                new Error("You have been disconnected. Please login again."),
            );
        }
        return response;
    });
};

export const handleHTTPResponse = (response: Response) => {
    if (!response.ok) {
        const errorMessage =
            `HTTP request failed: ${response.statusText} ` +
            `(url: ${response.url}, code ${response.status})`;
        return Promise.reject(new Error(errorMessage));
    }
    return response.json();
};

export const handleHTTPError = (error: Error) => {
    // console.log(error);
    toast.error(error.message, { theme: "colored" });
};

export const generateSignalID = (channel: Channel, signal: Signal) => {
    const date = moment.utc(signal.date).format("YYYYMMDDHHmmss");
    const identifier = channel.name + signal.symbol + signal.side + date;
    return md5(identifier);
};
