import { Theme } from "@mui/material";

export default function Paper(theme: Theme) {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
                    borderRadius: ".25rem",
                    padding: theme.spacing(2.5),
                },
            },
        },
    };
}
